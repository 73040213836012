/*
Template Name: Admin Template
Author: Wrappixel

File: scss
*/
@import '../variable';
/*******************
Login register and recover password Page
******************/

.login-register{
  background-image: url('/assets/quarterbac/Quarterbac graphic 2k.png');
  background-size: contain;
  background-repeat: no-repeat;
 background-color: white;
  padding-top: calc(.1 * 10vh);
}
.workuplogo{
  background-image: url('/assets/quarterbac/workup nospace.png');
  background-size: 100%;
  background-repeat: no-repeat;
 background-color: white;
 
}

.customerlogo{
  background-image: url('/assets/quarterbac/customerlogo.png');
  background-size: 100%;
  background-repeat: no-repeat;
 background-color: white;
 
}
.phantomlogo{
  background-image: url('/assets/quarterbac/phantom no space.png');
  background-size: 100%;
  background-repeat: no-repeat;
 background-color: white;
 
}

.login-register.no-login-register-bg{
  background-image: none;
}

.skin-default .topbar-login{
  position: absolute;
  background: none;
  right: 20px;
}

.topbar-login-light-bg .navbar-dark .navbar-nav .nav-link{
  color: $body-color;
}

.bloodhound-tracker{
  &.login-register{
    background-image: url(../../../assets/images/bloodhound-tracker/login-background.jpg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top 0px right 390px;
    background-color: #fff;
    padding: 0;
  }
  .login-padding{
    margin-top: 180px;
  }

  .flag-icon{
    position: relative;
    top: 3px;
    margin-right: 5px;
  }

  .topbar .top-navbar .navbar-nav > .nav-item > .nav-link{
    line-height: 30px;
  }

  .topbar-login{
    right: 0px;
  }

}
