//enabld variables

//Override variables

/*Material Icons*/
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

/*Fonts*/
@import url(https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i&display=swap);
$headings-font-family: 'Lato', sans-serif !default;
$headings-font-weight: 400 !default;
$font-family-sans-serif: 'Lato', sans-serif !default;
$font-weight-extra-bold: 900 !default;

/*background & colours*/
//TODO variable not overriding.
$body-bg: #ecede8 !default;

//New variables

/*Theme Colors*/
$primary:  #0F1E32 !default;
$enabld-green: #50C837 !default;
$enabld-dark-blue: #0F1E32 !default;
$quarterbac-navy: #0c1137 !default;
$quarterbac-orange: #ef6d27 !default;
$quarterbac-blue: #27a9e1 !default;
$enabld-light-gray: #ecede8 !default;
$enabld-dark-gray: #ced0c6 !default;
$enabld-light-blue: #717f8f !default;


//mixin

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  border-radius: $radius;

}
.logincenter {
 
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -30%);
  padding: 10px;
 
}

